<template lang="pug">
    b-card(
        header-tag='header', 
        footer='',
        footer-tag='footer').product-card
        modal-documents(ref='modalDocuments', :productSelected='products')
        modal-confirm(ref='modalConfirmMail', @email="setEmail")
        modal-vouchers(ref="modalVouchers", :productSelected='products', :files="files")
        template(#header='')
            p O/S:
                strong  {{ products.NUMERO_GENERAL }}
            h3 {{ products.PRODUCTO }}
                span Estado: {{ products.ESTADO }}
        .text-center.mb-4
            b-button.btn(type='button' @click='openDocuments()' variant='default') Ver documentos
            b-button(type='button' @click="openVouchers()" variant='default' :disabled='isLoading') Comprobantes de pago
            b-button.btn(type='button' @click="openModalConfirm()" variant='default' :disabled='classOfCard(products.ESTADO)') Renovar
        p.period Periodo:
            strong  {{ products.FECHA_INICIO }}
            |  al
            strong  {{ products.FECHA_FIN }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import modalDocuments from '../documents'
import modalConfirmMail from '../paymentEmail'
import modalVouchers from '../vouchers'
export default {
    props: {
        products: null,
        vehicle: null
    },
    data () {
        return {
            isLoading: false,
            files: []
        }
    },
    components: {
        'modal-documents': modalDocuments,
        'modal-confirm': modalConfirmMail,
        'modal-vouchers': modalVouchers
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        ...mapActions(['PaymentService', 'GetListOfVoucher']),
        classOfCard (val) {
            return val === 'ACTIVO' // 'success' : 'pending'
        },
        openModalConfirm (val) {
            this.$refs.modalConfirmMail.open()
        },
        setEmail (val) {
            this.renewPayment(val)
        },
        async openVouchers (val) {
            try {
                this.isLoading = true
                let dto = {
                    numero_os: this.products.NUMERO_GENERAL.toString()
                    //numero_os: "1101901472"
                }
                const res = await this.GetListOfVoucher(dto)
                if (res.status == 'success') {
                    this.files = res.resultado
                    this.$refs.modalVouchers.open()
                } else if (res.status == 'sinDatos') {
                    this.$bvModal.msgBoxOk('No cuentas con comprobantes para este producto.', {
                        title: 'Hunter',
                        centered: true
                    })
                } else {
                    this.$bvModal.msgBoxOk('Ocurrió un error inesperado. Vuelva a intentarlo más tarde.', {
                        title: 'Hunter',
                        centered: true
                    })
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        async renewPayment(email) {
            try {
                let dto = {
                    PRECIO: this.products.TOTAL_OS,
                    MONEDA: this.products.MONEDA,
                    ORDER_ID: this.products.NUMERO_GESNERAL,
                    PLACA: this.vehicle.PLACA,
                    PRODUCTO: this.products.PRODUCTO,
                    DNI_RUC: this.user.ID_CLIENTE,
                    TELEFONO: this.user.TELEFONO,
                    CORREO_CLIENTE: email,
                    NOMBRE_CLIENTE: this.user.PRIMER_NOMBRE + ' ' + this.user.APELLIDO_PATERNO + ' ' + this.user.APELLIDO_MATERNO 
                }
                const res = await this.PaymentService(dto)
                if (res.status == 'success') {
                    const anchor = document.createElement('a')
                    anchor.href = res.data.paymentURL
                    anchor.target="_blank"
                    anchor.click()
                } else {
                    this.$bvToast.toast('Ocurrió un error inesperado. Vuelva a intentarlo más tarde.', {
                        title: 'Hunter',
                        autoHideDelay: 50000,
                        appendToast: false
                    })
                }
            } catch (error) {
                console.log(error)
            }
        },
        openDocuments () {
            this.$refs.modalDocuments.open()
        },
    }
}
</script>

<style lang="scss">
    @import './style.scss';
</style>