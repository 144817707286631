<template lang="pug">
    b-modal(v-model='modalConfirmMail', v-if="modalConfirmMail", :hide-footer='true', :modal-class='[ "modal-confirm" ]', :centered='true', size='md', title='Confirmar correo')
        .modal-confirm-container.text-left
            p Confirma tu correo electronico para generar 
                | la constancia de pago. Si no tienes registrado un correo con nosotros, deberás ingresar uno para poder continuar.
            b-form-group(label='' label-for='input-1')
                b-form-input(type="text", v-model='mail', placeholder='Ingresa un correo')
                .error(v-if="(!$v.mail.required && $v.mail.$anyDirty)") Campo obligatorio
                .error(v-if="!$v.mail.email && $v.mail.$anyDirty") Correo inválido
        .text-right.mt-5
            b-button(type='submit' @click='close()'  variant='default') Cancelar
            b-button(type='submit' @click='confirm()' variant='primary') Continuar
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

export default {
    name: 'modalConfirmMail',
    data () {
        return {
            modalConfirmMail: false,
            mail: ''
        }
    },
    validations: {
        mail: {
            email,
            required
        }
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        open () {
            this.modalConfirmMail = true
        },
        close () {
            this.modalConfirmMail = false
            this.$v.$reset()
        },
        confirm () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                console.log('Validar formulario')
            } else {
                this.$emit('email', this.mail)
                this.close()
            }
        },
        withEmail () {
            if (this.user?.CORREO) {
                return this.user.CORREO.length != 0
            }
            return false
        }
    },
    created () {
        if (this.withEmail()) {
            this.mail = this.user.CORREO
        }
    }
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
