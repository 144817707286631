<template lang="pug">
    div
        b-table(striped hover :items="products" :fields="columns")
            template(v-for='slot in Object.keys($scopedSlots)' v-slot:[toCellName(slot)]='props')
                slot(:name="slot" v-bind="props")
</template>

<script>
export default {
    props: {
        products: Array,
        columns: Array
    },
    methods: {
        toCellName (slot) {
            return `cell(${slot})`
        }
    }
}
</script>