<template lang="pug">
  b-modal(v-model='modalDocuments', :hide-footer='true', :modal-class='[ "modal-documents" ]', :centered='true', size='lg')
    template(#modal-title='')
        .title
            strong {{ productSelected.PRODUCTO }}
            span Documentos del producto
    b-overlay(:show="isLoading" rounded="lg" opacity="0.6")
      .modal-documents-container
        b-row
          b-col.item(md='4')
            p Condiciones de Servicio
            img(:src='CondicionesServicioImg')
            ul
                li
                    p(@click="getDocument('generarPDFCondicion')")
                        font-awesome-icon(:icon="faDownloadIcon")
                        | Descargar documento
                li
                    p(@click="sendMailButton('Condiciones_de_servicio.pdf')")
                        font-awesome-icon(:icon="faEnvelopeIcon")
                        | Enviar por correo
          b-col.item(md='4')
            p Certificado de Propiedad
            img(:src='CertificadoPropiedadImg')
            ul
                li
                    p(@click="getDocument('generarPDFCertificadoInstalacion')")
                        font-awesome-icon(:icon="faDownloadIcon")
                        | Descargar documento
                li
                    p(@click="sendMailButton('Certificado_de_propiedad.pdf')")
                        font-awesome-icon(:icon="faEnvelopeIcon")
                        | Enviar por correo
          b-col.item(md='4')
            p Cartilla de Seguridad
            img(:src='CartillaSeguridadImg')
            ul
                li
                    p
                      a(href="http://sys.hunterlojack.com/files/CartillaSeguridad.pdf", target="_blank").two-lines-svg
                        font-awesome-icon(:icon="faDownloadIcon")
                        | Descargar documento
                li
                    p(@click="sendMailButton('Cartilla_de_seguridad.pdf')")
                        font-awesome-icon(:icon="faEnvelopeIcon")
                        | Enviar por correo
            //- b-col(md='3')
            //-     .item
            //-         p Comprobante de pago
            //-         img(:src='ComprobanteImg')
            //-         ul
            //-             li
            //-                 p
            //-                   a(:href="`http://sys.hunterlojack.com/files/${product.NUMERO_GENERAL}_comprobantePago.pdf`", target="_blank").two-lines-svg
            //-                     font-awesome-icon(:icon="faDownloadIcon")
            //-                     | Descargar
            //-                     br
            //-                     | documento
            //-             li
            //-                 p(@click="sendMailButton('Comprobante_de_pago.pdf')")
            //-                     font-awesome-icon(:icon="faEnvelopeIcon")
            //-                     | Enviar por correo
        .text-right
            b-button(type='submit' @click='close()'  variant='primary') Aceptar
</template>

<script>
import CondicionesServicio from '../../assets/images/documentos-1.png'
import CertificadoPropiedad from '../../assets/images/documentos-2.png'
import CartillaSeguridad from '../../assets/images/documentos-3.png'
import Comprobante from '../../assets/images/documentos-4.png'
import { faDownload, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'modalDocuments',
  props: {
    productSelected: Object
  },
  data () {
    return {
      modalDocuments: false,
      isLoading: false,
      CondicionesServicioImg: CondicionesServicio,
      CertificadoPropiedadImg: CertificadoPropiedad,
      CartillaSeguridadImg: CartillaSeguridad,
      ComprobanteImg: Comprobante
    }
  },
  computed: {
    ...mapState(['user']),
    faDownloadIcon () {
      return faDownload
    },
    faEnvelopeIcon () {
      return faEnvelope
    }
  },
  methods: {
    ...mapActions(['SendMail', 'GenerateDocument', 'GetCertificated']),
    open () {
      this.modalDocuments = true
    },
    close () {
      this.modalDocuments = false
    },
    async getDocument (name, os) {
      try {
        this.isLoading = true
        let dto = {
          name: name,
          os: this.productSelected.NUMERO_GENERAL
        }
        const res = await this.GenerateDocument(dto)
        if(res.status == "success") {
          this.downloadFile({
            name: name,
            os: res.os
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async downloadFile (dto) {
      let nameFile = ''

      switch (dto.name) {
        case 'generarPDFCertificadoInstalacion':
          nameFile = 'Certificado_de_Instalacion.pdf'
          dto.os = dto.os + '/vac/1/RSB'
          break;
        case 'generarPDFCondicion':
          nameFile = 'generarPDFCondicion.pdf'
          break;
        default:
          break;
      }

      const res = await this.GetCertificated(dto)
      if (res.status == 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nameFile);
        document.body.appendChild(link);
        link.click();
      }
    },
    async sendMailButton (msj) {
      try {
        this.isLoading = true
        let dto = {
          numeroGeneral: this.productSelected.NUMERO_GENERAL,
          documento: msj,
          para: this.user.CORREO,
          copia: '',
          mensaje: 'Solicitud de documentos'
        }
        const res = await this.SendMail(dto)
        if(res.status == "success") {
          this.$bvModal.msgBoxOk('Documento enviado.', {
                title: 'Hunter',
                centered: true
              })
        } else {
          this.$bvModal.msgBoxOk('Error al enviar. Intentelo nuevamente.', {
                title: 'Hunter',
                centered: true
              })
        }
      }
      catch(error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    
  }
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
