<template lang="pug">
  b-modal(v-model='modalVouchers', :hide-footer='true', :modal-class='[ "modal-vouchers" ]', :centered='true', size='xs')
    template(#modal-title='')
        .title
            strong {{ productSelected.PRODUCTO }}
            span Descargar comprobantes de pago
    b-overlay(:show="isLoading" rounded="lg" opacity="0.6")
      .modal-vouchers-container
          ul
              li(v-for="item in files", :key="item.id_comprobante")
                  p(@click="downloadFile(item)", type="button")
                      | {{ item.DOCUMENTO }}.pdf
                      font-awesome-icon(:icon="faDownloadIcon")
      .text-right
          b-button(type='submit' @click='close()'  variant='primary') Aceptar
</template>

<script>
import { faDownload, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'modalVouchers',
  props: {
    productSelected: Object,
    files: Array
  },
  data () {
    return {
      modalVouchers: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState(['user']),
    faDownloadIcon () {
      return faDownload
    },
    faEnvelopeIcon () {
      return faEnvelope
    }
  },
  methods: {
    ...mapActions(['GetVoucher']),
    open () {
      this.modalVouchers = true
    },
    close () {
      this.modalVouchers = false
    },
    async downloadFile (dto) {
        try {
            this.isLoading = true
            const res = await this.GetVoucher(dto.id_comprobante)
            if (res.status == 200) {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', dto.DOCUMENTO + '.pdf');
                document.body.appendChild(link);
                link.click();
            }
        } catch (error) {
            console.log(error)
        } finally {
            this.isLoading = false
        }
    }
  }
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
