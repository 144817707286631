<template lang="pug">
  b-row.title
    b-col(md='12')
      h1 {{ firstTitle }}
        span {{ subTitle }}
</template>

<script>
export default {
  name: 'Title',
  props: {
    firstTitle: String,
    subTitle: String
  }
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
