<template lang="pug">
  .products
    side-bar
    alert-bar
    title-page(firstTitle='Mis productos', subTitle='Control de productos')
    .container-bg
      b-row.mb-4
        b-col(md="3")
          .detail-vehicle(v-if="findData")
            h5 Vehículo: {{ findData.PLACA }}
              span Placa: {{ findData.ESTADO ? 'Vigente' : 'Pendiente de aprobación' }}
        b-col(md="6", v-if="datos.length > 0")
          label.d-block.mb-2.font-small.font-bold Estado:
          b-tabs(style='', pills='', v-model='tabIndex')
            b-tab(title='Todos' active)
            b-tab(title='Por renovar')
            b-tab(title='Vigentes')
          //- b-row
          //-   data-table(:products="changeTab" :columns="fields")
          //-     template(v-slot:actions='row')
          //-       b-button(type='button' @click="renewPayment()" variant='default') Renovar
          //-       //- :disabled='classOfCard(product.ESTADO)'
          //-       b-button(@click='requestCheckup("012ABC")' variant='default') Solicitar chequeo
          //-       b-button(type='button' @click='openDocuments(product)' variant='default') Ver documentos
      b-row
        b-col(md='4', v-for='(item,  index) in changeTab' v-if="changeTab.length > 0" :key='item.CODIGO')
          product-component(:products="item", :vehicle="findData")
      b-row(v-if="changeTab.length == 0")
        notResults
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Title from '../components/title'
import DataTable from '../components/dataTable'
import ProductComponent from '../components/product'
import notResults from '../components/notResultsComponent'
export default {
  name: 'Products',
  data () {
    return {
      search: '',
      datos: [],
      tabIndex: 0,
      fields: [
        {
          key: 'PRODUCTO',
          label: 'Producto',
          sortable: true
        },
        {
          key: 'NUMERO_GENERAL',
          label: 'Orden de servicio'
        },
        {
          key: 'FECHA_INICIO',
          label: 'Fecha de inicio',
          sortable: true
        },
        {
          key: 'FECHA_FIN',
          label: 'Fecha de fin',
          sortable: true
        },
        {
          key: 'ESTADO',
          label: 'Estado'
        },
        {
          key:'actions'
        }
      ]
    }
  },
  components: {
    'title-page': Title,
    'product-component': ProductComponent,
    'data-table': DataTable,
    notResults
  },
  computed: {
    ...mapState(['vehicles']),
    findData () {
      return this.vehicles.filter(x => x.CODIGO === parseInt(this.$router.history.current.params.id))[0]
    },
    changeTab () {
      if (this.tabIndex === 0) {
        return this.datos
      } else if (this.tabIndex === 1) {
        return this.datos.filter(x => x.ESTADO !== 'ACTIVO')
      } else {
        return this.datos.filter(x => x.ESTADO === 'ACTIVO')
      }
    }
  },
  methods: {
    ...mapActions(['GetProductsByVehicle']),
    searchData () {
      console.log(this.search)
      return this.datos.filter(x => Object.values(x)
        .join(' ')
        .toLowerCase()
        .includes(this.search.toLowerCase()))
    },
    async listOfProducts () {
      const idVehiculo = this.$router.history.current.params.id
      const res = await this.GetProductsByVehicle(idVehiculo)
      this.datos = res
    }
  },
  created () {
    if (this.vehicles.length > 0) this.listOfProducts()
    else this.$router.push('/')
  }
}
</script>

<style lang="scss">
    @import '../assets/scss/Products/style.scss';
</style>
